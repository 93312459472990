<template>
  <div class="">
    <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :busy="busy"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(image_url)="data">
        <div
          class="position-relative box-image"
          v-bind:style="{
            'background-image': 'url(' + data.item.image_url + ')',
          }"
        ></div>
      </template>
      <template v-slot:cell(sort_order)="data">
        <div>{{ data.item.sort_order }}</div>
      </template>
      <template v-slot:cell(see_detail)="data">
        <span v-show="false">{{ data.item.see_detail }}</span>
        <b-button
          class="see-detail-underline pointer"
          @click="seeDetail(data.item)"
          >Detail</b-button
        >
      </template>
      <template v-slot:cell(valid_from)="data">
        <div class="justify-content-center">
          <div>{{ $moment(data.item.valid_from).format("DD/MM/YYYY") }}</div>
          <div class="time">
            ({{ $moment(data.item.valid_from).format("HH:mm:ss") }})
          </div>
        </div>
      </template>
      <template v-slot:cell(valid_to)="data">
        <div class="justify-content-center">
          <div>{{ $moment(data.item.valid_to).format("DD/MM/YYYY") }}</div>
          <div class="time">
            ({{ $moment(data.item.valid_to).format("HH:mm:ss") }})
          </div>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="d-flex justify-content-center">
          <span v-show="false">{{ data.item.action }}</span>
          <b-button
            variant="link"
            class="text-warning px-1 py-0"
            @click.prevent="deleteProduct(data)"
            :disabled="checkRedeem"
          >
            <font-awesome-icon
              icon="trash-alt"
              title="Delete"
              class="main-color"
            />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-start"
      >
        <p class="mb-0 p-gray">
          Showing
          <span v-if="items.length === 0" id="from-row">0</span>
          <span v-else>{{ currentPage }}</span> to <span>{{ perPage }}</span> of
          <span>{{ totalRows }}</span> entires
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-end mt-2 mt-sm-0"
      >
        <div>
          <b-pagination
            :total-rows="totalRows"
            v-model="currentPage"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            class="m-0"
            last-text="Last"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    busy: {
      required: true,
      type: Boolean,
    },
    checkRedeem: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      sortBy: "sort_order",
      sortDesc: false,
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    deleteProduct(data) {
      this.$emit("deletePrivilegeDetail", data);
    },
    seeDetail(data) {
      if (data.coupon_id) {
        this.$emit("seeDetailCoupon", data.coupon_id);
      } else {
        this.$emit("seeDetailCoupon", data.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.time {
  color: #afafaf;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
.see-detail-underline {
  text-decoration: underline !important;
  background-color: transparent;
  border: none;
}
</style>
