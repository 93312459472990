<template>
  <div>
    <b-row>
      <b-col>
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          :disabled="disable"
          v-model="form.privilegeCode.name"
          :v="v.form.privilegeCode.name"
          :isValidate="v.form.privilegeCode.name.$error"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label class="font-weight-bold main-label">
          Description<span class="text-danger">*</span>
        </label>
        <b-form-textarea
          :class="[
            'mb-3',
            v.form.privilegeCode.short_description.$error ? 'border-error' : '',
          ]"
          id="textarea-rows"
          :value="form.privilegeCode.short_description"
          :v-model="form.privilegeCode.short_description"
          :disabled="disable"
          rows="3"
          @change="handleChangeDescription"
        >
        </b-form-textarea>
        <div v-if="v.form.privilegeCode.short_description.$error">
          <span
            class="text-error"
            v-if="v.form.privilegeCode.short_description.$error"
            >Please input text</span
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TextEditorsTiny
          textFloat="Detail"
          placeholder="Detail"
          isRequired
          :value="form.privilegeCode.description"
          :v-model="form.privilegeCode.description"
          :v="v.form.privilegeCode.description"
          :isValidate="v.form.privilegeCode.description.$error"
          @onDataChange="(val) => (form.privilegeCode.description = val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <UploadFile
          textFloat="Cover Photo"
          placeholder="Upload Image"
          format="all"
          :fileName="form.privilegeCode.image_url"
          v-model="form.privilegeCode.image_url"
          name="thumbnail"
          text="*Please upload only file .png, .jpg with ratio 1:1"
          isRequired
          :accept="acceptType"
          :v="v.form.privilegeCode.image_url"
          v-on:onFileChange="onImageChange"
          v-on:delete="deleteImage"
          :isValidate="v.form.privilegeCode.image_url.$error"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <div
          class="preview-box"
          v-bind:style="{
            'background-image': 'url(' + form.privilegeCode.image_url + ')',
          }"
        >
          <img
            src="@/assets/icons/loading.svg"
            class="loading"
            alt="loading"
            v-if="isLoadingImage"
          />
          <font-awesome-icon
            icon="times-circle"
            class="text-secondary delete-icon pointer"
            v-if="form.privilegeCode.image_url"
            @click="deleteImage"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <InputText
          class=""
          textFloat="Privilege Code"
          placeholder="Privilege Code"
          type="text"
          name="codeprefix"
          isRequired
          :disabled="disable"
          v-model="form.privilegeCode.prefix"
          :v="v.form.privilegeCode.prefix"
          :isValidate="v.form.privilegeCode.prefix.$error"
          @onKeypress="onKeypressArticleNo"
        />
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col md="6">
        <InputText
          
          textFloat="Number Of Privileges"
          placeholder="Number Of Privileges"
          type="number"
          name="quantity"
          isRequired
          v-model="form.privilegeCode.quantity"
          :v="v.form.privilegeCode.quantity"
          :isValidate="v.form.privilegeCode.quantity.$error"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <InputText
          textFloat="No."
          placeholder="No."
          type="number"
          name="quantity"
          isRequired
          :disabled="checkRedeem"
          v-model="form.privilegeCode.sort_order"
          :v="v.form.privilegeCode.sort_order"
          :isValidate="v.form.privilegeCode.sort_order.$error"
        />
      </b-col>
      <b-col>
        <InputText
          textFloat="Valid days (After Exchanging Coupons)"
          placeholder="Valid days"
          type="number"
          name="quantity"
          isRequired
          :disabled="checkRedeem"
          v-model="form.privilegeCode.expired_after_redeem"
          :v="v.form.privilegeCode.expired_after_redeem"
          :isValidate="v.form.privilegeCode.expired_after_redeem.$error"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="mb-3">
        <label class="label-text">
          Sale Price Start Date (DD/MM/YYYY)
          <span class="text-danger">*</span>
        </label>
        <div
          :class="[
            'input-container',
            v.form.privilegeCode.valid_from.$error ? 'border-error' : '',
          ]"
        >
          <datetime
            type="datetime"
            :input-style="styleDatetime"
            v-model="form.privilegeCode.valid_from"
            :disabled="checkRedeem"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateStart"
          >
          </datetime>
          <div
            :class="
              'icon-container text-right ' + id == 0 ? '' : 'cursor-default'
            "
            @click="$refs.transferDateStart.isOpen = id == 0 ? true : false"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                id == 0
                  ? 'pointer color-primary'
                  : 'cursor-default color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.privilegeCode.valid_from.$error">
          <span
            class="text-error"
            v-if="v.form.privilegeCode.valid_from.required == false"
            >Please select date time.</span
          >
        </div>
      </b-col>
      <b-col md="6">
        <label class="label-text"> Sale Price End Date (DD/MM/YYYY) </label>
        <div
          :class="[
            'input-container',
            v.form.privilegeCode.valid_to.$error ? 'border-error' : '',
          ]"
        >
          <datetime
            type="datetime"
            :input-style="styleDatetime"
            v-model="form.privilegeCode.valid_to"
            placeholder="Please Select Date"
            format="dd/MM/yyyy (HH:mm)"
            value-zone="Asia/Bangkok"
            ref="transferDateEnd"
            :min-datetime="form.privilegeCode.valid_from"
            :isValidate="v.form.privilegeCode.valid_to.$error"
            :v="v.form.privilegeCode.valid_to"
          >
          </datetime>
          <div
            class="icon-container text-right"
            @click="$refs.transferDateEnd.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="
                id == 0
                  ? 'pointer color-primary'
                  : 'cursor-default color-primary'
              "
              color="#B41BB4"
            />
          </div>
        </div>
        <div v-if="v.form.privilegeCode.valid_to.$error">
          <span
            class="text-error"
            v-if="v.form.privilegeCode.valid_to.required == false"
            >Please select date time.</span
          >
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <label class="font-weight-bold main-label">
          Display Status
          <span class="text-danger">*</span>
        </label>
        <div>
          <b-form-checkbox
            switch
            v-model="form.privilegeCode.status"
            class="radio-active"
            size="lg"
          >
            <span class="ml-2 main-label">{{
              form.privilegeCode.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
export default {
  components: {
    InputText,
    UploadFile,
    TextEditorsTiny,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    isDisable: {
      required: true,
      type: Boolean,
    },
    checkRedeem: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      disable: this.isDisable,
      image: {
        height: "",
        width: "",
      },
    };
  },
  methods: {
    onKeypressArticleNo(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45
      ) {
        // return allowedEng;
      } else {
        e.preventDefault();
      }
    },
    handleChangeDescription(value) {
      this.form.privilegeCode.short_description = value;
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.disable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
            this.isLoadingImage = false;
            this.disable = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    async setDataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      this.isLoadingImage = false;
      this.disable = false;
      this.form.privilegeCode.image_url = url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.privilegeCode.image_url = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.border-error {
  border-color: red;
}
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.delete-icon {
  float: right;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
}
::v-deep .custom-select:disabled {
  background-color: transparent;
}
</style>
