<template>
  <b-sidebar
    ref="sideBarCoupon"
    v-model="showSidebar"
    right
    no-close-on-backdrop
    backdrop
    shadow
    no-header
  >
    <template>
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Coupon Detail</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
      </div>
    </template>
    <div>
      <div v-if="isLoading">
        <OtherLoading />
      </div>
      <div v-else>
        <div class="bg-white p-3">
          <b-row>
            <b-col>
              <InputText
                textFloat="Name"
                placeholder="Name"
                type="text"
                name="name"
                isRequired
                :isValidate="$v.form.name.$error"
                v-model="form.name"
                :v="$v.form.name"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div>
                <label class="label-custom"
                  >Discount <span class="text-error">*</span></label
                >
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Discount"
                    max
                    v-model="form.promotion_value"
                    :disabled="chkValid == 1 ? true : false"
                    oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text"
                      v-if="getPercent"
                      :disabled="chkValid == 1 ? true : false"
                      @click="getPercentClick(false)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'percent']"
                        class="percent"
                      />
                    </button>
                    <button
                      class="input-group-text"
                      v-else
                      :disabled="chkValid == 1 ? true : false"
                      @click="getPercentClick(true)"
                    >
                      <span class="percent">Baht</span>
                    </button>
                  </div>
                </div>
                <div v-if="$v.form.promotion_value.$error">
                  <div class="input-error">Please input.</div>
                </div>
              </div>
              <div
                class="input-error"
                v-if="!$v.form.promotion_value.isValidPromo"
              >
                Discount value must less than or equal to 100%
              </div>
              <!-- <InputText
                textFloat="ส่วนลด"
                placeholder="ส่วนลด"
                type="number"
                name="discount"
                isRequired
                v-model="form.promotion_value"
                :v="$v.form.promotion_value"
                :isValidate="$v.form.promotion_value.$error"
                class="discount-position"
                :disabled="chkValid == 1 ? true : false"
              />
              <button
                class="percent"
                v-if="getPercent"
                :disabled="chkValid == 1 ? true : false"
                @click="getPercentClick(false)"
              >
                <font-awesome-icon :icon="['fas', 'percent']" />
              </button>
              <button
                class="percent"
                v-else
                :disabled="chkValid == 1 ? true : false"
                @click="getPercentClick(true)"
              >
                Baht
              </button>
              <div class="input-error" v-if="$v.form.promotion_value.$anyError">
                Discount value must less than or equal to 100%
              </div> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-3">
              <InputTextArea
                textFloat="Description"
                placeholder="Description"
                :value="form.short_description"
                isRequired
                :v="$v.form.short_description"
                :isValidate="$v.form.short_description.$error"
                v-model="form.short_description"
                rows="3"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <TextEditorTinyCoupon
                textFloat="Detail"
                placeholder="Detail"
                :value="form.description"
                v-if="!isLoadingTiny"
                @onDataChange="(val) => (form.description = val)"
                isRequired
                v-model="form.description"
                :v="$v.form.description"
                :isValidate="$v.form.description.$error"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <UploadFile
                v-model="form.image_url"
                :value="form.image_url"
                textFloat="Cover Photo"
                placeholder="Please Select a File."
                format="image"
                name="thumbnail"
                accept="image/jpeg, image/png"
                text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                isRequired
                :isValidate="$v.form.image_url.$error"
                v-on:onFileChange="onImageChange"
                :fileName="form.image_url"
                :v="$v.form.image_url"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <div>
                <div
                  cols="8"
                  class="img-box-preview b-contain mb-3"
                  v-bind:style="{
                    'background-image': 'url(' + showPreview + ')',
                  }"
                >
                  <img
                    src="@/assets/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingImage"
                  />
                  <div class="text-right">
                    <font-awesome-icon
                      icon="times-circle"
                      class="text-secondary delete-icon pointer"
                      v-if="form.image_url"
                      @click="onDeleteImage"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InputText
                textFloat="Coupon Code"
                placeholder="Coupon Code"
                type="text"
                name="prefix"
                :isValidate="$v.form.prefix.$error"
                isRequired
                v-model="form.prefix"
                :v="$v.form.prefix"
                @onKeypress="onKeypressArticleNo"
              />
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <InputText
                textFloat="Number Of Privileges"
                placeholder="Number Of Privileges"
                type="number"
                name="max_quantity"
                isRequired
                v-model="form.max_quantity"
                :v="$v.form.max_quantity"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InputText
                textFloat="Minimum Transaction Value (Baht)"
                placeholder="Minimum Transaction Value (Baht)"
                type="number"
                name="condition"
                isRequired
                v-model="form.required_point"
                :v="$v.form.required_point"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InputText
                textFloat="Valid days (After Exchanging Coupons)"
                placeholder="Valid days (After Exchanging Coupons)"
                type="number"
                name="activated"
                isRequired
                v-model="form.activated"
                :v="$v.form.activated"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label class="label-custom"
                >Sale Price Start Date (DD/MM/YYYY)
                <span class="text-error">*</span></label
              >
              <div
                :class="[
                  chkValid == 1
                    ? 'input-container input-disable'
                    : 'input-container',
                ]"
              >
                <datetime
                  type="datetime"
                  :input-class="[
                    $v.form.start_datetime.$error
                      ? 'border-error styleDatetime'
                      : 'styleDatetime',
                  ]"
                  v-model="form.start_datetime"
                  placeholder="DD/MM/YYYY (HH:MM)"
                  format="dd/MM/yyyy (HH:mm)"
                  value-zone="Asia/Bangkok"
                  :disabled="chkValid == 1 ? true : false"
                  ref="transferDateStart"
                >
                </datetime>
                <div
                  :class="
                    'icon-container text-right ' + id == 0
                      ? ''
                      : 'cursor-default'
                  "
                  @click="chkValid == 1 ? true : false"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="
                      chkValid == 1
                        ? 'cursor-default color-primary'
                        : 'pointer color-primary'
                    "
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="$v.form.start_datetime.$error" class="input-error">
                Please select date.
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <label class="label-custom"
                >Sale Price End Date (DD/MM/YYYY)
                <span class="text-error">*</span></label
              >
              <div class="input-container">
                <datetime
                  type="datetime"
                  :input-style="styleDatetime"
                  :input-class="[
                    $v.form.end_datetime.$error ? 'border-error' : '',
                  ]"
                  v-model="form.end_datetime"
                  placeholder="DD/MM/YYYY (HH:MM)"
                  format="dd/MM/yyyy (HH:mm)"
                  value-zone="Asia/Bangkok"
                  ref="transferDateEnd"
                  :min-datetime="form.start_datetime"
                >
                </datetime>
                <div
                  class="icon-container text-right"
                  @click="$refs.transferDateEnd.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'pointer color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
              <div v-if="$v.form.end_datetime.$error" class="input-error">
                Please select date.
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <label class="label-custom"
                >Points To Be Earned (After Exchanging Coupons)
                <span class="text-error">*</span></label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    class="input-group-text padding-multiple"
                    v-if="multiplyPointIcon"
                    :disabled="chkValid == 1 ? true : false"
                    @click="multiplyPointIcon = false"
                  >
                    <span class="icon-multiple">+</span>
                  </button>
                  <button
                    class="input-group-text padding-multiple"
                    v-else
                    :disabled="chkValid == 1 ? true : false"
                    @click="multiplyPointIcon = true"
                  >
                    <span class="icon-multiple">x</span>
                  </button>
                </div>
                <input
                  type="number"
                  class="form-control"
                  @change="onAddPoint"
                  :disabled="chkValid == 1 ? true : false"
                  placeholder="Points To Be Earned (After Exchanging Coupons)"
                  v-model="pointShow"
                  oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <div class="no-gutters bg-white">
                <b-form-checkbox
                  switch
                  v-model="form.status"
                  class="radio-active"
                  size="lg"
                  :value="1"
                  :unchecked-value="0"
                >
                  <span class="ml-2 main-label">{{
                    form.status ? "Active" : "Inactive"
                  }}</span>
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="bg-white pl-3 pr-3 pb-3">
          <b-row class="tab-coupon">
            <b-col>D-Store Integration</b-col>
            <b-col class="text-right"></b-col>
          </b-row>
          <div class="mt-2">
            <!-- Promotional items -->
            <b-row>
              <b-col>
                <label class="label-custom"
                  >Participating products (in D-Store system)</label
                >
                <b-form-group>
                  <b-row class="mt-3">
                    <b-col>
                      <b-form-radio
                        v-model="chkProduct"
                        class="text-detail text-black"
                        :value="0"
                        :disabled="this.allUnSelected ? true : false"
                      >
                        All
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio
                        v-model="chkProduct"
                        class="text-detail text-black"
                        :value="1"
                        :disabled="this.allUnSelected ? true : false"
                      >
                        Please select product(s)
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <!-- Select Product -->
            <div v-if="chkProduct == 1">
              <div>
                <b-button
                  class="btn-select-branch text-black"
                  @click.prevent="selectProduct"
                >
                  Select Item
                  <font-awesome-icon icon="chevron-right" class="ft-14" />
                </b-button>
                <span class="text-black ml-3"
                  >Selected Item {{ this.productList.length }} List</span
                >
              </div>
              <SelectProductList
                :fieldProductSelect="fieldProductSelect"
                :items="productList"
                :rows="rows"
                :filter="filter"
                :id="idCouponString"
                @deleteSelectProduct="deleteSelectProduct"
              />
            </div>
            <!-- Branch -->
            <div class="my-3">
              <label class="label-custom">Participating Branches</label>
              <div class="my-3">
                <b-button class="btn-select-branch" @click="showBranch">
                  Select Branch
                  <font-awesome-icon icon="chevron-right" class="ft-14" />
                </b-button>
                <span class="text-black ml-3">
                  (Selected Branch {{ countBranch }} List)</span
                >
              </div>
              <div v-if="errorMessageBranch" class="input-error">
                Please select a branch that participates in the program.
              </div>
            </div>
          </div>
          <div class="space-bottom"></div>
        </div>
        <div
          class="footer d-flex justify-content-between shadow align-items-center"
        >
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click.prevent="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="checkForm"
              block
              variant="light"
              id="handle-search-filter"
              class="text-body rounded-pill btn-main"
            >
              Save
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="branchList && branchList.length > 0">
      <ModalSelectBranch
        ref="ModalSelectBranch"
        :list="branchList"
        @deleteBranch="(val) => (deleteBranchArry = val)"
        @selectBranch="selectBranch"
        :id="idCouponString"
        :valid="chkValid"
      />
    </div>
    <ModalSelectProduct
      ref="modalSelectProduct"
      :filter="filter"
      @product="selectProductFunc"
      @delete="(val) => (this.dataDeleteProduct = val)"
      @searchProduct="searchProduct"
      :columns="columns"
      :productLists="productListItems"
      :productListSelect="dataSelectProduct"
      :rows="rows"
      :id="id"
      :valid="chkValid"
      :isLoading="isLoadingProduct"
      @filterpage="filterPage"
      @searchCategory="searchCategory"
      :list_ah1="list_ah1"
      :list_ah2="list_ah2"
      :list_ah3="list_ah3"
      :list_ah4="list_ah4"
      @getProductAgain="getProductAgain"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </b-sidebar>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";
import TextEditorTinyCoupon from "@/components/inputs/TextEditorTinyCoupon";
import UploadFile from "@/components/inputs/UploadFile";
import ModalSelectProduct from "@/components/coupon/detail/ModalSelectProduct";
import ModalSelectBranch from "@/components/coupon/modal/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import SelectProductList from "@/components/coupon/detail/SelectProductList";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    InputText,
    InputTextArea,
    TextEditorTinyCoupon,
    UploadFile,
    ModalSelectProduct,
    ModalSelectBranch,
    OtherLoading,
    SelectProductList,
    ModalAlertError,
  },
  validations: {
    form: {
      name: { required },
      prefix: { required },
      max_quantity: { required },
      promotion_value: {
        required,
        isValidPromo: function (value) {
          if (this.getPercent === true && value > 100) return false;
          return true;
        },
      },
      image_url: { required },
      short_description: { required },
      description: { required },
      start_datetime: { required },
      end_datetime: { required },
    },
  },
  data() {
    return {
      showSidebar: false,
      id: "0",
      form: {
        name: "",
        allow_other_promotion: false,
        prefix: "",
        max_quantity: 0,
        promotion_value: 0,
        required_point: 0,
        availability: 1,
        activated: 0,
        productSelect: [],
        start_datetime: "",
        end_datetime: "",
        BranchSelect: [],
        status: 0,
        promotion_discount_type_id: 1,
        minimum_amount_requirement: 0,
        user_group_id: 1,
        branch_point: 0,
        promotion_type_id: 4,
        cmg_promotion_value: 0,
        cmg_promotion_discount_type_id: 0,
        image_url: "",
        short_description: "",
        description: "",
        multiply_point: 0,
        point: 0,
      },
      chkValid: 0,
      isLoadingImage: false,
      showPreview: "",
      confirmText: "",
      errorMessageForm: false,
      errorMessageTo: false,
      isDisable: false,
      allUnSelected: false,
      chkProduct: 0,
      countBranch: 0,
      errorMessageBranch: false,
      branchList: [],
      deleteBranchArry: [],
      productList: [],
      getPercent: true,
      countProductSelect: [],
      idCoupon: 0,
      countIdUpdate: [],
      isLoading: true,
      isLoadingTiny: true,
      fieldProductSelect: [
        {
          label: "Photo",
          key: "imge_url",
        },
        {
          label: "Name/",
          key: "name",
          tdClass: "w-45-custom",
        },
        {
          label: "SKU",
          key: "article_no",
        },
        {
          label: "Price",
          key: "price",
        },
        {
          label: "",
          key: "action",
        },
      ],
      rows: 0,
      filter: {
        select_product: [],
        get_product: {
          start: 0,
          length: 5,
          search: "",
          ah1: "",
          ah2: "",
          ah3: "",
          ah4: "",
        },
      },
      columns: [
        {
          label: "",
          key: "is_check",
        },
        {
          label: "Photo",
          key: "imge_url",
        },
        {
          label: "Name/Detail",
          key: "name",
        },
        {
          label: "SKU",
          key: "article_no",
        },
        {
          label: "Price",
          key: "price",
        },
      ],
      productListItems: [],
      isLoadingProduct: true,
      dataSelectProduct: [],
      list_ah1: [],
      list_ah2: [],
      list_ah3: [],
      list_ah4: [],
      modalMessage: "",
      idCouponString: "0",
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      multiplyPointIcon: true,
      pointShow: 0,
    };
  },
  watch: {
    showSidebar(val) {
      if (val) {
        setTimeout(async () => {
          this.isLoadingTiny = false;
        }, 1000);
      } else {
        this.isLoadingTiny = true;
      }
    },
    multiplyPointIcon() {
      if (this.multiplyPointIcon) {
        this.form.point = this.pointShow;
        this.form.multiply_point = 0;
      } else {
        this.form.multiply_point = this.pointShow;
        this.form.point = 0;
      }
    },
  },
  methods: {
    onKeypressArticleNo(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45
      ) {
        // return allowedEng;
      } else {
        e.preventDefault();
      }
    },
    async show(id) {
      if (id && id !== true) {
        this.idCoupon = id;
        await this.getCouponDetail();
        await this.getProductList();
      } else {
        this.idCoupon = id;
        this.$v.form.$reset();
        this.form = {
          name: "",
          allow_other_promotion: false,
          prefix: "",
          max_quantity: 0,
          promotion_value: 0,
          required_point: 0,
          availability: 1,
          activated: 0,
          productSelect: [],
          start_datetime: "",
          end_datetime: "",
          BranchSelect: [],
          status: 1,
          promotion_discount_type_id: 1,
          minimum_amount_requirement: 0,
          user_group_id: 1,
          branch_point: 0,
          promotion_type_id: 4,
          cmg_promotion_value: 0,
          cmg_promotion_discount_type_id: 0,
          image_url: "",
          short_description: "",
          description: "",
        };
        this.showPreview = "";
        this.errorMessageBranch = false;
        this.chkValid = 0;
        this.chkProduct = 0;
        this.branchList = [];
        this.deleteBranchArry = [];
        this.productList = [];
        this.getPercent = true;
        this.countProductSelect = [];
        this.productListItems = [];
        this.dataSelectProduct = [];
      }
      this.idCouponString = id.toString();
      await this.getBranchList();
      this.showSidebar = true;
    },
    hide() {
      this.$emit("closeSidebar");
      this.showSidebar = false;
    },
    async getCouponDetail() {
      await this.$store.dispatch("getCouponDetail", this.idCoupon);
      let data = this.$store.state.coupon.couponDetail;
      this.chkValid = data.detail.check_valid;
      if (data.result == 1) {
        this.isLoading = false;
        this.form = data.detail.detail;
        this.productList = data.detail.product_list;
        this.form.status = this.form.status == 1 ? true : false;
        if (this.form.image_url) this.showPreview = this.form.image_url;
        this.chkProduct = this.form.product_group_id == 1 ? 0 : 1;
        if (this.chkValid == 1) {
          if (this.form.product_group_id == 1) {
            this.allUnSelected = true;
          } else {
            this.allUnSelected = false;
          }
        }
        this.getPercent =
          this.form.promotion_discount_type_id == 1 ? true : false;
        let productSelect = [];
        for (const product of this.productList) {
          productSelect.push(product.id);
        }
        this.multiplyPointIcon = this.form.multiply_point > 0 ? false : true;
        if (this.multiplyPointIcon) {
          this.pointShow = this.form.point;
        } else {
          this.pointShow = this.form.multiply_point;
        }
        this.countProductSelect = productSelect;
        this.dataSelectProduct = productSelect;
        await this.getProductList("first");
      } else {
        this.isLoading = false;
      }
    },
    async getProductList(callFunc, value) {
      this.isLoadingProduct = true;
      if (callFunc === "first") {
        this.filter.select_product = this.countProductSelect;
      } else if (callFunc === "getproduct") {
        this.filter.select_product = Array.from(new Set(value));
      } else {
        this.filter.select_product = Array.from(
          new Set(this.dataSelectProduct)
        );
      }
      this.form.productSelect = Array.from(new Set(this.dataSelectProduct));
      // this.filter.delete_product = this.dataDeleteProduct;
      this.form.productDelete = this.dataDeleteProduct;
      let data = {
        id_group_product: 0,
        form: this.filter,
      };
      await this.$store.dispatch("getCouponProductList", data);
      let dataResponse = this.$store.state.coupon.couponProductList;
      if (dataResponse.result == 1) {
        this.isLoadingProduct = false;
        this.productListItems = dataResponse.detail.product_list;
        this.list_ah1 = dataResponse.detail.list_ah1
          ? dataResponse.detail.list_ah1
          : [];
        this.list_ah2 = dataResponse.detail.list_ah2
          ? dataResponse.detail.list_ah2
          : [];
        this.list_ah3 = dataResponse.detail.list_ah3
          ? dataResponse.detail.list_ah3
          : [];
        this.list_ah4 = dataResponse.detail.list_ah4
          ? dataResponse.detail.list_ah4
          : [];
        this.rows = dataResponse.detail.total_product;
      } else {
        this.isLoadingProduct = false;
      }
    },
    getPercentClick(status) {
      this.getPercent = status;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.image_url = this.images;
        this.showPreview = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.image_url = "";
      this.showPreview = "";
    },
    confirmHideModal() {
      if (this.actionStatus == "save") {
        this.submitForm();
      } else if (this.actionStatus == "delete") {
        this.deleteData();
      } else {
        this.$refs.RedeemOrderList.changeStatus();
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(true);
    },
    showBranch() {
      this.$refs.ModalSelectBranch.show();
    },
    async getBranchList() {
      let id = 0;
      if (parseInt(this.idCoupon) !== 0) id = this.form.branch_group_id;
      await this.$store.dispatch("getBranch", id);
      if (!this.$store.state.coupon.modalAlertLogin) {
        this.branchList = this.$store.state.coupon.branchList.detail;
        if (parseInt(this.idCoupon) !== 0) {
          for (let i in this.branchList) {
            for (let j in this.branchList[i].branch) {
              if (this.branchList[i].branch[j].is_check == true) {
                this.countIdUpdate.push(this.branchList[i].branch[j].id);
              }
            }
          }
          this.countBranch = this.countIdUpdate.length;
        } else {
          this.countBranch = 0;
          this.countIdUpdate = [];
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.showModal = true;
      }
    },
    selectBranch(val) {
      this.form.BranchSelect = val;
      this.countBranch = val.length;
    },
    deleteSelectProduct(val) {
      const findProductDelete = this.productList.findIndex(
        (ele) => ele.id === val
      );
      const findIdSelectDelete = this.dataSelectProduct.findIndex(
        (el) => el === val
      );
      this.productList.splice(findProductDelete, 1);
      this.dataSelectProduct.splice(findIdSelectDelete, 1);
      this.dataDeleteProduct.push(val);
    },
    selectProductFunc(val) {
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...val.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      const product = [...this.productList, ...val.productItemSelect];
      const result = [];
      const map = new Map();
      for (const item of product) {
        if (!map.has(item.id)) {
          map.set(item.id, true); // set any value to Map
          result.push({
            ah_no: item.ah_no,
            article_no: item.article_no,
            article_type: item.article_type,
            barcode: item.barcode,
            description: item.description,
            id: item.id,
            imge_url: item.imge_url,
            is_check: item.is_check,
            marked: item.marked,
            name: item.name,
            ph_no: item.ph_no,
            point: item.point,
            price: item.price,
          });
        }
      }
      this.productList = result;
    },
    searchProduct(val) {
      this.filter.get_product.search = val.filter;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...val.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    searchCategory(value) {
      this.filter = value.searchCategory;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...value.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    filterPage(val) {
      this.filter.get_product.start = val.page;
      this.dataSelectProduct = [
        ...this.dataSelectProduct,
        ...val.productSelect,
      ];
      this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
      this.getProductList();
    },
    getProductAgain(val) {
      this.filter.select_product = val;
      this.filter.get_product = {
        start: 0,
        length: 5,
        search: "",
        ah1: "",
        ah2: "",
        ah3: "",
        ah4: "",
      };
      this.getProductList("getproduct", val);
    },
    onAddPoint(event) {
      if (this.multiplyPointIcon) {
        this.form.point = parseInt(event.target.value);
      } else {
        this.form.multiply_point = parseInt(event.target.value);
      }
    },
    async checkForm() {
      if (parseInt(this.idCoupon) === 0) {
        if (this.form.BranchSelect.length === 0) {
          this.errorMessageBranch = true;
        } else {
          this.errorMessageBranch = false;
        }
      }
      this.form.promotion_discount_type_id = this.getPercent ? 1 : 2;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      if (this.errorMessageBranch) {
        return;
      }
      this.isLoading = true;
      if (this.chkProduct == 0) {
        this.form.productSelect = [];
      } else {
        this.form.productSelect = this.dataSelectProduct;
      }
      if (this.form.allow_other_promotion) {
        this.form.allow_other_promotion = 0;
      } else {
        this.form.allow_other_promotion = 1;
      }
      if (this.form.status) {
        this.form.status = 1;
      } else {
        this.form.status = 0;
      }
      if (parseInt(this.idCoupon) === 0) {
        await this.$store.dispatch("createCoupon", this.form);
        let data = this.$store.state.coupon.responseCreateCoupon;
        if (data.result == 1) {
          this.$emit("couponSuccess", {
            type: "created",
            id: data.detail,
          });
          this.showSidebar = false;
          this.isLoading = false;
        } else {
          if (this.form.status == 1) {
            this.form.status = true;
          } else {
            this.form.status = false;
          }
          this.isLoading = false;
          this.$refs.modalAlertError.show();
          this.modalMessage = data.message;
          this.showModal = true;
          this.errorMsg = data.message;
        }
      } else {
        this.dataSelectProduct = Array.from(new Set(this.dataSelectProduct));
        let data = {
          id: this.idCoupon,
          form: {
            name: this.form.name,
            start_datetime: this.form.start_datetime,
            end_datetime: this.form.end_datetime,
            status: this.form.status,
            promotion_value: this.form.promotion_value,
            promotion_discount_type_id: this.getPercent ? 1 : 2,
            required_point: this.form.required_point,
            allow_other_promotion: this.form.allow_other_promotion,
            prefix: this.form.prefix,
            max_quantity: this.form.max_quantity,
            availability: this.form.availability,
            activated: this.form.activated,
            created_by_guid: "",
            product_group_id: this.form.product_group_id,
            productSelect:
              this.chkProduct === 0
                ? []
                : this.dataSelectProduct.length > 0
                ? this.dataSelectProduct
                : this.countProductSelect,
            BranchSelect: this.form.BranchSelect
              ? this.form.BranchSelect
              : this.countIdUpdate,
            image_url: this.form.image_url,
            short_description: this.form.short_description,
            description: this.form.description,
            multiply_point: this.form.multiply_point
              ? parseInt(this.form.multiply_point)
              : 0,
            point: this.form.point ? parseInt(this.form.point) : 0,
          },
        };
        await this.$store.dispatch("updateCouponById", data);
        let respData = this.$store.state.coupon.statusUpdateCoupon;
        if (respData.result == 1) {
          this.$emit("couponSuccess", "update");
          this.showSidebar = false;
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.$refs.modalAlertError.show();
          this.modalMessage = respData.message;
          this.showModal = true;
          this.errorMsg = respData.message;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-black {
  color: #000;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .b-sidebar {
  width: 600px;
  background-color: #cccccc !important;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.img-box-preview {
  width: 59%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.label-custom {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.tab-coupon {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.btn-select-branch {
  width: 33%;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 25px;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  padding-top: 0;
  background: #fff;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.space-bottom {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
::v-deep .w-45-custom {
  width: 45% !important;
}
.input-error {
  color: red;
}
::v-deep .border-error {
  border-color: red !important;
}
::v-deep .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #2eb85c;
  color: #fff;
  border-color: #2eb85c;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.input-disable {
  background-color: #fafafa;
}
::v-deep .vdatetime-input.styleDatetime {
  border: none;
}
.percent {
  font-size: 13px;
}
</style>
